import { IAddressInfo, ILicenceInfo } from 'src/types/myInfo';
import { toShiftDateTimeOffsetFormat } from 'src/utils';
import { vehicleDrivingLicenseValidityStatuses } from './constants/drivingLicenseValidityStatuses';

export const mapIdentifierType = (id: string) => (id.startsWith('F') || id.startsWith('G') ? 'Passport' : 'NationalIdentifier');

export const mapFullAddress = ({
    block, street, floor, unit, building, country, postal,
}: IAddressInfo) => `BLK ${block} ${street} ${floor}-${unit} ${building} ${country} ${postal}`;

export const mapDate = (dateOfBirth: string | undefined) => {
    if (!dateOfBirth) return '';

    const [year, month, date] = dateOfBirth.split('-');

    return `${date ?? '01'}/${month ?? '01'}/${year ?? '1980'}T00:00:00+00:00`;
};

export const mapGender = (gender: any) => {
    if (gender == null) return '';

    const genderStr = gender.toLowerCase();
    const formattedGender = genderStr.charAt(0).toUpperCase() + genderStr.slice(1);

    return `EntityGender.${formattedGender}`;
};

export const mapIsLicenceRevoked = (value: string | undefined) => (!(value == null || value === 'V'));

export const mapDrivingLicenceClass = (licenseClass: string) => {
    if (licenseClass == null) return '';

    let mappedClass;
    switch (licenseClass.toLocaleUpperCase()) {
    case '2':
    case '2A':
    case '2B':
        mappedClass = 'Motorcycle';
        break;
    case '3CA':
    case '3A':
    case '3C':
    case '3':
        mappedClass = 'Car';
        break;
    case '4A':
    case '4':
        mappedClass = 'Bus';
        break;
    case '5':
        mappedClass = 'Truck';
        break;
    default: mappedClass = undefined;
    }
    if (mappedClass !== undefined) {
        return `DrivingLicenseType.${mappedClass}`;
    } return undefined;
};

export const mapLicenseValidity = (license: ILicenceInfo) => {
    if (!license.validity) return undefined;
    const licenseValidity = vehicleDrivingLicenseValidityStatuses[license.validity.toLocaleUpperCase() ?? ''];
    let resultValidity;
    let resultSourceValidity;
    if (licenseValidity) {
        resultValidity = `DrivingLicenseValidityStatus.${licenseValidity}`;
    } else {
        resultSourceValidity = license.validity;
    }
    const mappedValidity = {
        drivingLicenseNumber: '-1',
        licenseValidity: resultValidity,
        licenseValiditySourceName: resultSourceValidity,
    };
    return mappedValidity;
};

export const mapDrivingLicenses = (license: ILicenceInfo) => license.classes?.map((item, i) => ({
    number: `-${i + 1}`,
    licenseType: mapDrivingLicenceClass(item.class),
    startDate: toShiftDateTimeOffsetFormat(item.issueDate),
    licenseTypeSourceName: item.class,
    drivingLicenseHasBeenRevoked: mapIsLicenceRevoked(license.validity),
    licenseValidity: mapLicenseValidity(license)?.licenseValidity,
    licenseValiditySourceName: mapLicenseValidity(license)?.licenseValiditySourceName,
}));
